<template>
  <div id="page">
    <Header />
    <Footer />
    <PageTitle :title="'Content'" />
    <div class="page-title-clear"></div>

    <div class="page-content">
      <div class="page-title page-title-fixed"></div>
      <div class="page-title-clear"></div>

      <div class="card card-style">
        <div class="content mb-0">
          <p class="mb-n1 color-highlight font-600"></p>
          <h1 class="font-26 font-800"></h1>
          <p></p>

          <a href="#" class="mb-4 d-block">
            <div class="responsive-iframe mb-3">
              <iframe
                src="https://www.youtube.com/embed/CC0buGnRovU"
                frameborder="0"
                allowfullscreen
              ></iframe>
            </div>
            <h5 class="font-600 pt-2"></h5>
            <span class="color-highlight opacity-60"></span>
          </a>

          <a href="#" class="mb-4 d-block">
            <div class="responsive-iframe mb-3">
              <iframe
                src="https://www.youtube.com/embed/Hg5nW3_6F6s"
                frameborder="0"
                allowfullscreen
              ></iframe>
            </div>
            <h5 class="font-600 pt-2"></h5>
            <span class="color-highlight opacity-60"></span>
          </a>

          <a href="#" class="mb-4 d-block">
            <div class="responsive-iframe mb-3">
              <iframe
                src="https://www.youtube.com/embed/bo-zvdXCoJo"
                frameborder="0"
                allowfullscreen
              ></iframe>
            </div>
            <h5 class="font-600 pt-2"></h5>
            <span class="color-highlight opacity-60"></span>
          </a>

          <a href="#" class="mb-4 d-block">
            <div class="responsive-iframe mb-3">
              <iframe
                src="https://www.youtube.com/embed/athlev1JU-E"
                frameborder="0"
                allowfullscreen
              ></iframe>
            </div>
            <h5 class="font-600 pt-2"></h5>
            <span class="color-highlight opacity-60"></span>
          </a>

          <a href="#" class="mb-4 d-block">
            <div class="responsive-iframe mb-3">
              <iframe
                src="https://www.youtube.com/embed/8WsUHNxRVis"
                frameborder="0"
                allowfullscreen
              ></iframe>
            </div>
            <h5 class="font-600 pt-2"></h5>
            <span class="color-highlight opacity-60"></span>
          </a>
        </div>
      </div>

      <div data-menu-load="menu-footer.html"></div>
    </div>
    <!-- Page content ends here-->
    <div @click="menuClose" class="menu-hider"></div>
  </div>
</template>

<script>
import Footer from "@/components/Footer";
import Header from "@/components/Header";
import PageTitle from "@/components/PageTitle";
import { init_template, menuClose } from "@/appkit.js";
export default {
  components: { Header, Footer, PageTitle },
  data() {
    return {
      event: {},
    };
  },
  mounted() {
    init_template();
  },
  methods: {
    menuClose,
  },
};
</script>
